import { render, staticRenderFns } from "./SocialAudienceContextRolesSelector.vue?vue&type=template&id=263e46d3&scoped=true&"
import script from "./SocialAudienceContextRolesSelector.vue?vue&type=script&lang=js&"
export * from "./SocialAudienceContextRolesSelector.vue?vue&type=script&lang=js&"
import style0 from "./SocialAudienceContextRolesSelector.vue?vue&type=style&index=0&id=263e46d3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "263e46d3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('263e46d3')) {
      api.createRecord('263e46d3', component.options)
    } else {
      api.reload('263e46d3', component.options)
    }
    module.hot.accept("./SocialAudienceContextRolesSelector.vue?vue&type=template&id=263e46d3&scoped=true&", function () {
      api.rerender('263e46d3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/shared/contextRoles/SocialAudienceContextRolesSelector.vue"
export default component.exports