import { ROLES } from '@/model/shared/roles';

export const ContextRoleSelectorMixin = {
  props: {
    /** @type ContextRole */
    value: {
      type: [Object, Array],
      default: () => null,
    },
    user: {
      /** @type UserCore */
      type: Object,
      default: () => null,
    },
  },
  data() {
    return { mainRole: null };
  },
  computed: {
    currentContextRole() {
      const contextRole = this.value || { context: this.context.id, settings: {} };
      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }
      return contextRole;
    },
    isToggleChecked() {
      return !!this.currentContextRole?.role;
    },
  },
  methods: {
    setMainRole() {
      this.mainRole = this.currentContextRole?.role ? ROLES[this.currentContextRole.role] : null;
    },
    onMainRoleChange() {
      this.emitValue();
    },
    emitValue() {
      const value = !this.mainRole ? null : this.currentContextRole;
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
