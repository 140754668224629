import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

export const getResources = () => ({
  [ADVERTISER_RESOURCE]: undefined,
});
/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:Advertiser[],meta:EndpointMeta}>}
 */
export async function getAdvertisers(params = new QueryParamsBuilder()) {
  params.addInclude(['client']);
  const partialUrl = api.createUrl(getResources());
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build();
}
