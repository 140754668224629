import { AVAILABLE_CONTEXTS, CONTEXTS } from '@/model/shared/contexts';
import { Role, ROLES } from '@/model/shared/roles';

const possibleContexts = AVAILABLE_CONTEXTS;
delete possibleContexts.CORE;

const CONFIG = {
  errors: {
    password: null,
    email: null,
  },
  coreContext: {
    [CONTEXTS.CORE.id]: CONTEXTS.CORE,
  },
  optionsAuth: {
    [ROLES.ADMIN.id]: ROLES.ADMIN,
    USER: new Role('USER', 'User'),
  },
  possibleContexts,
};
export default CONFIG;
