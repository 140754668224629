<template>
  <context-role-selector-base
    v-model="mainRole"
    class="context-selector w-full"
    :toggle="{ text: context.name, checked: isToggleChecked }"
    :options="options"
    @change="onMainRoleChange"
  />
</template>

<script>
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';

export default {
  name: 'FallbackContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    context: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    options() {
      return this.context.roles || [];
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.setMainRole();
      },
    },
  },
  created() {
    this.setMainRole();
  },
};
</script>
