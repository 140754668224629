var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-center w-full context-selector" },
    [
      _c("context-role-selector-base", {
        staticClass: "w-full",
        class: { "custom-form-row": _vm.simple },
        attrs: {
          simple: _vm.simple,
          toggle: { text: _vm.contextName, checked: _vm.isToggleChecked },
          options: _vm.roles,
        },
        on: { change: _vm.onMainRoleChange },
        model: {
          value: _vm.mainRole,
          callback: function ($$v) {
            _vm.mainRole = $$v
          },
          expression: "mainRole",
        },
      }),
      _vm.isClientOrSeller
        ? _c("form-row", {
            class: { "custom-form-row": _vm.simple },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Client" } },
                        [
                          _c("asterix-async-select", {
                            attrs: {
                              id: "clientSelectorThirdParty",
                              name: "clientSelectorThirdParty",
                              value: _vm.selectedClients,
                              service: _vm.getClients,
                              disabled: _vm.simple,
                              multiple: "",
                              required: "required",
                            },
                            on: { change: _vm.onClientChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              707056935
            ),
          })
        : _vm._e(),
      _vm.isAdvertiser
        ? _c("form-row", {
            class: { "custom-form-row": _vm.simple },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Client" } },
                        [
                          _c("asterix-async-select", {
                            key: "client-selector",
                            attrs: {
                              id: "clientSelectorThirdParty",
                              disabled: _vm.simple,
                              name: "clientSelectorThirdParty",
                              required: "required",
                              service: _vm.getClients,
                              value: _vm.selectedClientAdvertiser,
                            },
                            on: { change: _vm.onClientChangeAdvertiser },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2039485714
            ),
          })
        : _vm._e(),
      _vm.isAdvertiser && _vm.showAdvertiserSelector
        ? _c("form-row", {
            class: { "custom-form-row": _vm.simple },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Advertisers" } },
                        [
                          _c("asterix-async-select", {
                            key: "advertiser-selector",
                            attrs: {
                              id: "advertiserSelectorThirdParty",
                              multiple: "",
                              name: "advertiserSelectorThirdParty",
                              required: "required",
                              "search-key": "advertiser.name",
                              service: _vm.getAdvertisers,
                              value: _vm.selectedAdvertisers,
                            },
                            on: { change: _vm.onAdvertisersChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3028874717
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }