<template>
  <div class="flex flex-col items-center w-full context-selector">
    <context-role-selector-base
      v-model="mainRole"
      :simple="simple"
      class="w-full"
      :class="{ 'custom-form-row': simple }"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="roles"
      @change="onMainRoleChange"
    />
    <form-row v-if="mainRole && !isAdminUser" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelector"
            :value="user.clients"
            :service="getClients"
            :disabled="simple"
            multiple
            required="required"
            @change="onClientChange"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="mainRole" class="custom-form-row">
      <template #right>
        <sun-label-group text="Salesforce User">
          <asterix-async-select
            id="seller-select-filter"
            close-on-select
            disable-selected-options
            label="name"
            name="seller-select-filter"
            required="required"
            :service="getSalesforceUsers"
            track-by="id"
            :value="selectedSalesforceUser"
            @change="onSalesforceUserChange"
          />
        </sun-label-group>
      </template>
    </form-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';
import { CONTEXTS } from '@/model/shared/contexts';
import { getSalesforceUsers } from '@/services/modules/sunStudio/salesforce';
import { getSalesforceUserById } from '@/services/modules/Core/salesforce';
import ApiRequest from '@/utils/apiRequest';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect.vue';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { ROLES } from '@/model/shared/roles';
import { getClients } from '@/services/modules/contextual/client';

export default {
  name: 'ContextualContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    AsterixAsyncSelect,
    FormRow,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    simple: {
      type: Boolean,
      default: () => false,
    },
    roles: {
      type: Array,
      default: () => CONTEXTS.CONTEXTUAL.roles,
    },
  },
  data: () => ({
    selectedSalesforceUser: null,
    selectedClients: [],
  }),
  computed: {
    isAdminUser() {
      if (!this.mainRole) return false;
      return this.mainRole.id === ROLES.ADMIN.id;
    },
    contextName() {
      return CONTEXTS.CONTEXTUAL.name;
    },
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.CONTEXTUAL.id, settings: {} };
      contextRole.settings = contextRole.settings || {};

      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }
      contextRole.settings.salesforceId = this.selectedSalesforceUser?.id;
      if (!this.isAdminUser) {
        contextRole.settings.ids = this.selectedClients.map(client => client.id);
      }
      return contextRole;
    },
  },
  async created() {
    await this.loadSalesforceUser();
    this.loadClient();
    this.setMainRole();
  },
  methods: {
    getClients,
    getSalesforceUsers,
    ...mapActions([CREATE_TOAST]),
    onSalesforceUserChange({ items }) {
      this.selectedSalesforceUser = items;
      this.emitValue();
    },
    onMainRoleChange() {
      if (this.mainRole.id === ROLES.ADMIN.id) this.selectedClients = [];
      this.emitValue();
    },
    onClientChange({ items }) {
      this.selectedClients = items;
      this.emitValue();
    },
    loadClient() {
      this.selectedClients = this.user?.clients;
    },
    async loadSalesforceUser() {
      const salesforceId = this.value?.settings?.salesforceId;
      if (!salesforceId) return;

      await ApiRequest(async () => {
        const { data } = await getSalesforceUserById(salesforceId);
        this.selectedSalesforceUser = data;
      }).catch(() => {
        this[CREATE_TOAST](Toast.error('Error', 'Salesforce user not found'));
      });
    },
  },
};
</script>
