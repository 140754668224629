var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-role-selector-base", {
        staticClass: "w-full context-selector",
        attrs: {
          toggle: { text: _vm.contextName, checked: _vm.isToggleChecked },
          options: _vm.options,
        },
        on: { change: _vm.onMainRoleChange },
        model: {
          value: _vm.mainRole,
          callback: function ($$v) {
            _vm.mainRole = $$v
          },
          expression: "mainRole",
        },
      }),
      _vm.error
        ? _c(
            "div",
            { staticClass: "flex mb-6" },
            [
              _c("warning-svg", { staticClass: "w-5 h-5 text-orange-400" }),
              _c("p", { staticClass: "ml-2 text-sm text-gray-700" }, [
                _vm._v("You are going to lose admin privileges"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }