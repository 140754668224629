import { render, staticRenderFns } from "./DashboardSSPContextRolesSelector.vue?vue&type=template&id=8804d144&scoped=true&"
import script from "./DashboardSSPContextRolesSelector.vue?vue&type=script&lang=js&"
export * from "./DashboardSSPContextRolesSelector.vue?vue&type=script&lang=js&"
import style0 from "./DashboardSSPContextRolesSelector.vue?vue&type=style&index=0&id=8804d144&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8804d144",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8804d144')) {
      api.createRecord('8804d144', component.options)
    } else {
      api.reload('8804d144', component.options)
    }
    module.hot.accept("./DashboardSSPContextRolesSelector.vue?vue&type=template&id=8804d144&scoped=true&", function () {
      api.rerender('8804d144', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/shared/contextRoles/DashboardSSPContextRolesSelector.vue"
export default component.exports