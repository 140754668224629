import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

/**
 *
 * @param {string} advertiserId
 * @returns {Promise.<Advertiser>}
 */
export async function getAdvertiserById(advertiserId) {
  const params = new QueryParamsBuilder();
  params.addInclude(['platform', 'agency']);

  const partialUrl = api.createUrl({
    [ADVERTISER_RESOURCE]: advertiserId,
  });

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build();
}
