var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-center w-full context-selector" },
    [
      _c("context-role-selector-base", {
        staticClass: "w-full",
        attrs: {
          toggle: { text: _vm.contextName, checked: _vm.isToggleChecked },
          options: _vm.roles,
        },
        on: { change: _vm.onMainRoleChange },
        model: {
          value: _vm.mainRole,
          callback: function ($$v) {
            _vm.mainRole = $$v
          },
          expression: "mainRole",
        },
      }),
      _vm.mainRole
        ? _c("form-row", {
            staticClass: "custom-form-row",
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Salesforce User" } },
                        [
                          _c("asterix-async-select", {
                            attrs: {
                              id: "seller-select-filter",
                              "close-on-select": "",
                              "disable-selected-options": "",
                              label: "name",
                              name: "seller-select-filter",
                              required: "required",
                              service: _vm.getSalesforceUsers,
                              "track-by": "id",
                              value: _vm.selectedSalesforceUser,
                            },
                            on: { change: _vm.onSalesforceUserChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              925913437
            ),
          })
        : _vm._e(),
      _vm.showClientSelector
        ? _c("form-row", {
            staticClass: "custom-form-row",
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Client" } },
                        [
                          _c("asterix-async-select", {
                            attrs: {
                              id: "client-select",
                              label: "name",
                              required: "required",
                              multiple: "",
                              service: _vm.getClients,
                              value: _vm.selectedClients,
                            },
                            on: { change: _vm.onClientChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3183661109
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }