var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-center w-full context-selector" },
    [
      _c("context-role-selector-base", {
        staticClass: "w-full",
        class: { "custom-form-row": _vm.simple },
        attrs: {
          simple: _vm.simple,
          toggle: { text: _vm.contextName, checked: _vm.isToggleChecked },
          options: _vm.roles,
        },
        on: { change: _vm.onMainRoleChange },
        model: {
          value: _vm.mainRole,
          callback: function ($$v) {
            _vm.mainRole = $$v
          },
          expression: "mainRole",
        },
      }),
      _vm.isAdOpsOrClient
        ? _c("form-row", {
            class: { "custom-form-row": _vm.simple },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Client" } },
                        [
                          _c("asterix-async-select", {
                            attrs: {
                              id: "clientSelectorSocialAudience",
                              value: _vm.selectedClientsAdOps,
                              service: _vm.getClients,
                              "is-array-object": "",
                              disabled: _vm.simple,
                              multiple: "",
                              required: "required",
                            },
                            on: { change: _vm.onClientChangeAdOps },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3286765845
            ),
          })
        : _vm._e(),
      _vm.isAdvertiser
        ? _c("form-row", {
            class: { "custom-form-row": _vm.simple },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Client" } },
                        [
                          _c("asterix-async-select", {
                            attrs: {
                              id: "clientSelectorSocialAudience",
                              value: _vm.selectedClientAdvertiser,
                              service: _vm.getClients,
                              disabled: _vm.simple,
                              "is-array-object": "",
                              required: "required",
                            },
                            on: { change: _vm.onClientChangeAdvertiser },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3882626592
            ),
          })
        : _vm._e(),
      _vm.isAdvertiser && _vm.showAdvertiserSelector
        ? _c("form-row", {
            class: { "custom-form-row": _vm.simple },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Advertisers" } },
                        [
                          _c("asterix-async-select", {
                            key: _vm.advertiserSelectKey,
                            attrs: {
                              id: "advertiserSelector",
                              value: _vm.selectedAdvertisers,
                              service: _vm.getAdvertisersList,
                              multiple: "",
                              "is-array-object": "",
                              required: "required",
                              filters: _vm.advertiserFilter,
                            },
                            on: { change: _vm.onAdvertisersChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1793196463
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }