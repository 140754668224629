<template>
  <div class="flex flex-col items-center w-full context-selector">
    <context-role-selector-base
      v-model="mainRole"
      :simple="simple"
      class="w-full"
      :class="{ 'custom-form-row': simple }"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="roles"
      @change="onMainRoleChange"
    />

    <form-row v-if="isAdOpsOrClient" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelectorSocialAudience"
            :value="selectedClientsAdOps"
            :service="getClients"
            is-array-object
            :disabled="simple"
            multiple
            required="required"
            @change="onClientChangeAdOps"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isAdvertiser" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelectorSocialAudience"
            :value="selectedClientAdvertiser"
            :service="getClients"
            :disabled="simple"
            is-array-object
            required="required"
            @change="onClientChangeAdvertiser"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isAdvertiser && showAdvertiserSelector" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Advertisers">
          <asterix-async-select
            id="advertiserSelector"
            :key="advertiserSelectKey"
            :value="selectedAdvertisers"
            :service="getAdvertisersList"
            multiple
            is-array-object
            required="required"
            :filters="advertiserFilter"
            @change="onAdvertisersChange"
          />
        </sun-label-group>
      </template>
    </form-row>
  </div>
</template>

<script>
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ROLES } from '@/model/shared/roles';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { getClients } from '@/services/modules/socialAudience/client';
import { getAdvertisers } from '@/services/modules/socialAudience/advertiser';
import { CONTEXTS } from '@/model/shared/contexts';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';

export default {
  name: 'SocialAudienceContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    AsterixAsyncSelect,
    FormRow,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    simple: {
      type: Boolean,
      default: () => false,
    },
    roles: {
      type: Array,
      default: () => CONTEXTS.SOCIAL_AUDIENCE.roles,
    },
  },
  data: () => ({
    selectedAdvertisers: [],
    selectedClientsAdOps: [],
    selectedClientAdvertiser: null,
  }),
  computed: {
    isAdvertiser() {
      return this.mainRole?.id === ROLES.ADVERTISER.id;
    },
    isAdOpsOrClient() {
      return this.mainRole?.id === ROLES.AD_OPS.id || this.mainRole?.id === ROLES.CLIENT.id;
    },
    showAdvertiserSelector() {
      return this.isAdvertiser && !!this.selectedClientAdvertiser;
    },
    contextName() {
      return CONTEXTS.SOCIAL_AUDIENCE.name;
    },
    advertiserFilter() {
      const selectedId = this.selectedClientAdvertiser?.id;
      return selectedId ? [{ name: 'client.id', value: selectedId }] : [];
    },
    advertiserSelectKey() {
      const selectedId = this.selectedClientAdvertiser?.id;
      return selectedId || '0';
    },
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.SOCIAL_AUDIENCE.id, settings: {} };
      contextRole.settings = contextRole.settings || {};

      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }

      if (this.isAdvertiser) {
        contextRole.settings.clientId = this.selectedClientAdvertiser?.id;
      } else {
        delete contextRole.settings.clientId;
      }

      if (this.isAdOpsOrClient || this.isAdvertiser) {
        contextRole.settings.ids = this.isAdOpsOrClient
          ? this.selectedClientsAdOps.map(client => client.id)
          : this.selectedAdvertisers.map(advertiser => advertiser.id);
      } else {
        delete contextRole.settings.ids;
      }

      return contextRole;
    },
    contextClient() {
      return this.user.clients.filter(client => client.type === CONTEXTS.SOCIAL_AUDIENCE.id);
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.initClients();
        this.initAdvertisers();
        this.setMainRole();
      },
    },
    mainRole() {
      if (this.simple) {
        this.initClients();
      }
    },
  },
  created() {
    this.initClients();
    this.initAdvertisers();
    this.setMainRole();
  },
  methods: {
    getClients,
    getAdvertisersList(params) {
      return getAdvertisers(params, this.selectedClientAdvertiser.id);
    },
    initClients() {
      this.selectedClientsAdOps = this.isAdOpsOrClient ? this.contextClient : [];
      this.selectedClientAdvertiser = this.contextClient.length ? this.contextClient[0] : null;
    },
    initAdvertisers() {
      this.selectedAdvertisers = this.user?.advertisers || [];
    },
    onClientChangeAdOps({ items }) {
      this.selectedClientsAdOps = items;
      this.selectedAdvertisers = [];

      this.emitValue();
    },
    onClientChangeAdvertiser({ items }) {
      this.selectedClientAdvertiser = items;
      this.selectedAdvertisers = [];

      this.emitValue();
    },
    onAdvertisersChange({ items }) {
      this.selectedAdvertisers = items;
      this.emitValue();
    },
  },
};
</script>

<style scoped>
::v-deep .custom-form-row > div:nth-child(1) {
  @apply hidden;
}
::v-deep .custom-form-row > div:nth-child(2) {
  @apply ml-0;
}
</style>
