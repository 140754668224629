import { getAdvertisers } from '@/services/modules/ThirdParty/advertiser/getAdvertisers';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:Advertiser[],meta:EndpointMeta}>}
 */
export function getAdvertisersForFilter(params = new QueryParamsBuilder()) {
  const name = params.filters?.name;
  params.addFilter('advertiser.name', name);
  params.addFilter('name', undefined);
  return getAdvertisers(params);
}
