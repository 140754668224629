var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("context-role-selector-base", {
    staticClass: "context-selector w-full",
    attrs: {
      toggle: { text: _vm.context.name, checked: _vm.isToggleChecked },
      options: _vm.options,
    },
    on: { change: _vm.onMainRoleChange },
    model: {
      value: _vm.mainRole,
      callback: function ($$v) {
        _vm.mainRole = $$v
      },
      expression: "mainRole",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }