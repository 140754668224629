var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-row", {
    staticClass: "context-selector-base items-center",
    scopedSlots: _vm._u(
      [
        !_vm.simple
          ? {
              key: "left",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex flex-1 mb-2 sm:mb-0" },
                    [
                      _c("sun-toggle", {
                        class: { "opacity-50": _vm.toggleDisabled },
                        attrs: {
                          id: `toggle-${_vm.toggleText}`,
                          name: `toggle-${_vm.toggleText}`,
                          disabled: _vm.toggleDisabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.emitEvents()
                          },
                        },
                        model: {
                          value: _vm.toggleValue,
                          callback: function ($$v) {
                            _vm.toggleValue = $$v
                          },
                          expression: "toggleValue",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "ml-4 text-left",
                          class: { "font-bold": _vm.toggleValue },
                        },
                        [_vm._v(_vm._s(_vm.toggleText))]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "right",
          fn: function () {
            return [
              _c("sun-select", {
                attrs: {
                  id: `select-${_vm.toggle.value}`,
                  name: _vm.selectName,
                  options: _vm.options,
                  "track-by": "id",
                  label: "name",
                  "color-tag": "gray",
                  disabled: _vm.disabledRoleSelect,
                  required: _vm.isSelectRequired,
                  "text-error": "Select option",
                },
                model: {
                  value: _vm.selectedRole,
                  callback: function ($$v) {
                    _vm.selectedRole = $$v
                  },
                  expression: "selectedRole",
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }