<template>
  <div class="flex flex-col items-center w-full context-selector">
    <context-role-selector-base
      v-model="mainRole"
      class="w-full"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="roles"
      @change="onMainRoleChange"
    />

    <form-row v-if="mainRole" class="custom-form-row">
      <template #right>
        <sun-label-group text="Salesforce User">
          <asterix-async-select
            id="seller-select-filter"
            close-on-select
            disable-selected-options
            label="name"
            name="seller-select-filter"
            required="required"
            :service="getSalesforceUsers"
            track-by="id"
            :value="selectedSalesforceUser"
            @change="onSalesforceUserChange"
          />
        </sun-label-group>
      </template>
    </form-row>
    <form-row v-if="showClientSelector" class="custom-form-row">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="client-select"
            label="name"
            required="required"
            multiple
            :service="getClients"
            :value="selectedClients"
            @change="onClientChange"
          />
        </sun-label-group>
      </template>
    </form-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';
import { CONTEXTS } from '@/model/shared/contexts';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect.vue';
import { getClients } from '@/services/modules/sunStudio/client';
import { getSalesforceUserById } from '@/services/modules/Core/salesforce';
import { getSalesforceUsers } from '@/services/modules/sunStudio/salesforce';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import ApiRequest from '@/utils/apiRequest';
import { Toast } from '@/model/shared/Toast';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'SunStudioContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    AsterixAsyncSelect,
    FormRow,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    roles: {
      type: Array,
      default: () => CONTEXTS.SUNSTUDIO.roles,
    },
  },
  data: () => ({
    selectedSalesforceUser: null,
    selectedClients: [],
  }),
  computed: {
    contextName() {
      return CONTEXTS.SUNSTUDIO.name;
    },
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.SUNSTUDIO.id, settings: {} };
      contextRole.settings = contextRole.settings || {};

      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }

      contextRole.settings.salesforceId = this.selectedSalesforceUser?.id;

      contextRole.settings.ids = this.selectedClients.map(client => client.id);

      return contextRole;
    },
    showClientSelector() {
      return this.mainRole && this.mainRole !== ROLES.ADMIN;
    },
    contextClient() {
      return this.user.clients.filter(client => client.type === CONTEXTS.SUNSTUDIO.id);
    },
  },
  async created() {
    await this.loadSalesforceUser();
    this.setMainRole();
    this.initClients();
  },
  methods: {
    getClients,
    getSalesforceUsers,
    ...mapActions([CREATE_TOAST]),
    onSalesforceUserChange({ items }) {
      this.selectedSalesforceUser = items;
      this.emitValue();
    },
    async loadSalesforceUser() {
      const salesforceId = this.value?.settings?.salesforceId;
      if (!salesforceId) return;

      await ApiRequest(async () => {
        const { data } = await getSalesforceUserById(salesforceId);
        this.selectedSalesforceUser = data;
      }).catch(() => {
        this[CREATE_TOAST](Toast.error('Error', 'Salesforce user not found'));
      });
    },
    onClientChange({ items }) {
      this.selectedClients = items;
      this.emitValue();
    },
    initClients() {
      this.selectedClients = this.contextClient;
    },
  },
};
</script>
