import { getSalesforceUsers as getSalesforceUsersCore } from '@/services/modules/Core/salesforce';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Salesforce[], meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export const getSalesforceUsers = (params = new QueryParamsBuilder()) => {
  params.addFilter('email', '@sunmedia');
  params.addFilter('enabled', '1');
  return getSalesforceUsersCore(params);
};
