<template>
  <div class="flex flex-col px-4 pt-4 border rounded focus:outline-none focus:shadow-outline text-sm">
    <div v-for="(context, index) in privateContexts" :key="`private_context_${index}`">
      <component
        :is="getComponentByContext(context.id)"
        v-model="contextRoles[context.id]"
        :user="user"
        @change="onContextRoleChanged"
      />
    </div>
    <div v-for="(context, index) in publicContexts" :key="`toggle_${index}`">
      <component
        :is="getComponentByContext(context.id)"
        v-model="contextRoles[context.id]"
        :user="user"
        @change="onContextRoleChanged"
      />
    </div>
  </div>
</template>

<script>
import CONFIG from '@/components/organisms/modules/core/user/config';
import CoreContextRolesSelector from '@/components/organisms/shared/contextRoles/CoreContextRolesSelector';
import WarningSvg from '@/components/icons/WarningSvg';
import { CONTEXTS } from '@/model/shared/contexts';
import EcommerceContextRolesSelector from '@/components/organisms/shared/contextRoles/EcommerceContextRolesSelector';
import FallbackContextRolesSelector from '@/components/organisms/shared/contextRoles/FallbackContextRolesSelector';
import ThirdPartyContextRolesSelector from '@/components/organisms/shared/contextRoles/ThirdPartyContextRolesSelector';
import DashboardSSPContextRolesSelector from '@/components/organisms/shared/contextRoles/DashboardSSPContextRolesSelector';
import SocialAudienceContextRolesSelector from '@/components/organisms/shared/contextRoles/SocialAudienceContextRolesSelector';
import SunStudioContextRolesSelector from '@/components/organisms/shared/contextRoles/SunStudioContextRolesSelector';
import ContextualContextRolesSelector from '@/components/organisms/shared/contextRoles/ContextualContextRolesSelector';

export default {
  name: 'ContextRolesSelector',
  components: {
    CoreContextRolesSelector,
    EcommerceContextRolesSelector,
    ThirdPartyContextRolesSelector,
    DashboardSSPContextRolesSelector,
    SocialAudienceContextRolesSelector,
    SunStudioContextRolesSelector,
    ContextualContextRolesSelector,
    FallbackContextRolesSelector,
    WarningSvg,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    user: {
      /** @type UserCore */
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    contextRoles: [],
  }),
  computed: {
    privateContexts() {
      return CONFIG.coreContext;
    },
    publicContexts() {
      return CONFIG.possibleContexts;
    },
  },
  watch: {
    value() {
      this.initContextRoles();
    },
  },
  created() {
    this.initContextRoles();
  },
  methods: {
    /**
     * This method takes the ContextRole Array and transforms it to an indexed array. This makes the template easier
     */
    initContextRoles() {
      this.contextRoles = {};
      this.value.forEach(cx => {
        this.contextRoles[cx.context] = cx;
      });
    },
    onContextRoleChanged() {
      const payload = Object.values(this.contextRoles).filter(cr => !!cr);
      this.$emit('input', payload);
      this.$emit('change', payload);
    },
    getComponentByContext(contextId) {
      switch (contextId) {
        case CONTEXTS.CORE.id:
          return 'CoreContextRolesSelector';
        case CONTEXTS.ECOMMERCE.id:
          return 'EcommerceContextRolesSelector';
        case CONTEXTS.THIRD_PARTY.id:
          return 'ThirdPartyContextRolesSelector';
        case CONTEXTS.DASHBOARD_SSP.id:
          return 'DashboardSSPContextRolesSelector';
        case CONTEXTS.SOCIAL_AUDIENCE.id:
          return 'SocialAudienceContextRolesSelector';
        case CONTEXTS.SUNSTUDIO.id:
          return 'SunStudioContextRolesSelector';
        case CONTEXTS.CONTEXTUAL.id:
          return 'ContextualContextRolesSelector';
        default:
          return 'FallbackContextRolesSelector';
      }
    },
  },
};
</script>

<style scoped>
::v-deep .context-selector {
  @apply mb-6;
}
</style>
