var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col px-4 pt-4 border rounded focus:outline-none focus:shadow-outline text-sm",
    },
    [
      _vm._l(_vm.privateContexts, function (context, index) {
        return _c(
          "div",
          { key: `private_context_${index}` },
          [
            _c(_vm.getComponentByContext(context.id), {
              tag: "component",
              attrs: { user: _vm.user },
              on: { change: _vm.onContextRoleChanged },
              model: {
                value: _vm.contextRoles[context.id],
                callback: function ($$v) {
                  _vm.$set(_vm.contextRoles, context.id, $$v)
                },
                expression: "contextRoles[context.id]",
              },
            }),
          ],
          1
        )
      }),
      _vm._l(_vm.publicContexts, function (context, index) {
        return _c(
          "div",
          { key: `toggle_${index}` },
          [
            _c(_vm.getComponentByContext(context.id), {
              tag: "component",
              attrs: { user: _vm.user },
              on: { change: _vm.onContextRoleChanged },
              model: {
                value: _vm.contextRoles[context.id],
                callback: function ($$v) {
                  _vm.$set(_vm.contextRoles, context.id, $$v)
                },
                expression: "contextRoles[context.id]",
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }