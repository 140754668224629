<template>
  <div>
    <context-role-selector-base
      v-model="mainRole"
      class="w-full context-selector"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="options"
      @change="onMainRoleChange"
    />
    <div v-if="error" class="flex mb-6">
      <warning-svg class="w-5 h-5 text-orange-400" />
      <p class="ml-2 text-sm text-gray-700">You are going to lose admin privileges</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WarningSvg from '@/components/icons/WarningSvg';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { isAdminUser } from '@/utils/user/isAdminUser';
import { USER } from '@/store/modules/auth/keys';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';

export default {
  name: 'CoreContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    WarningSvg,
  },
  mixins: [ContextRoleSelectorMixin],
  computed: {
    ...mapGetters({
      loggedUser: USER,
    }),
    contextName() {
      return CONTEXTS.CORE.name;
    },
    options() {
      return CONTEXTS.CORE.roles || [];
    },
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.CORE.id, settings: {} };
      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }
      return contextRole;
    },
    error() {
      return isAdminUser(this.loggedUser) && this.mainRole?.id === ROLES.USER.id && this.loggedUser.id === this.user.id;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.setMainRole();
      },
    },
  },
  created() {
    this.setMainRole();
  },
};
</script>
