<template>
  <div class="flex flex-col items-center w-full context-selector">
    <context-role-selector-base
      v-model="mainRole"
      :simple="simple"
      class="w-full"
      :class="{ 'custom-form-row': simple }"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="roles"
      @change="onMainRoleChange"
    />

    <form-row v-if="isAdOpsOrClient" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelectorEcommerce"
            :value="selectedClientsAdOps"
            :service="getClients"
            :disabled="simple"
            multiple
            required="required"
            @change="onClientChangeAdOps"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isPublisher" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelector"
            :value="selectedClientPublishers"
            :service="getClients"
            :disabled="simple"
            required="required"
            multiple
            @change="onClientChangePublisher"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isPublisher && showPublisherSelector" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Publishers">
          <asterix-async-select
            id="publisherSelector"
            :key="publisherSelectKey"
            :value="selectedPublishers"
            :service="getPublishers"
            multiple
            required="required"
            :filters="publisherFilter"
            @change="onPublishersChange"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isAdvertiser" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelectorEcommerce"
            :disabled="simple"
            name="clientSelectorEcommerce"
            required="required"
            :service="getClients"
            :value="selectedClientAdvertiser"
            @change="onClientChangeAdvertiser"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isAdvertiser && showAdvertiserSelector" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Advertisers">
          <asterix-async-select
            id="advertiserSelectorEcommerce"
            :key="advertiserSelectKey"
            multiple
            name="advertiserSelectorEcommerce"
            required="required"
            search-key="advertiser.name"
            :service="getAdvertisers"
            :value="selectedAdvertisers"
            @change="onAdvertisersChange"
          />
        </sun-label-group>
      </template>
    </form-row>
  </div>
</template>

<script>
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ROLES } from '@/model/shared/roles';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { CONTEXTS } from '@/model/shared/contexts';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';
import { getClients } from '@/services/modules/ecommerce/client';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getPublishers } from '@/services/modules/ecommerce/publisher';
import { getAdvertisers } from '@/services/modules/ecommerce/advertiser';

export default {
  name: 'EcommerceContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    AsterixAsyncSelect,
    FormRow,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    simple: {
      type: Boolean,
      default: () => false,
    },
    roles: {
      type: Array,
      default: () => CONTEXTS.ECOMMERCE.roles,
    },
  },
  data: () => ({
    selectedPublishers: [],
    selectedClientsAdOps: [],
    selectedClientPublishers: null,
    selectedClientAdvertiser: null,
    selectedAdvertisers: [],
  }),
  computed: {
    isAdvertiser() {
      return [ROLES.ADVERTISER.id, ROLES.SUPER_ADVERTISER.id].includes(this.mainRole?.id);
    },
    isPublisher() {
      return this.mainRole?.id === ROLES.PUBLISHER.id;
    },
    isAdOpsOrClient() {
      return this.mainRole?.id === ROLES.AD_OPS.id || this.mainRole?.id === ROLES.CLIENT.id;
    },
    showPublisherSelector() {
      return this.isPublisher && !!this.selectedClientPublishers;
    },
    contextName() {
      return CONTEXTS.ECOMMERCE.name;
    },
    publisherFilter() {
      const selectedId = this.selectedClientPublishers?.id;
      return selectedId ? [{ name: 'client.id', value: selectedId }] : [];
    },
    publisherSelectKey() {
      const selectedId = this.selectedClientPublishers?.id;
      return selectedId || '0';
    },
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.ECOMMERCE.id, settings: {} };
      contextRole.settings = contextRole.settings || {};

      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }

      if (this.isPublisher) {
        if (this.selectedClientPublishers?.length > 0) {
          contextRole.settings.clientId = this.selectedClientPublishers.map(client => client.id);
        } else {
          contextRole.settings.clientId = null;
        }
      } else if (this.isAdvertiser) {
        if (Array.isArray(this.selectedClientAdvertiser) && this.selectedClientAdvertiser?.length > 0) {
          // advertiser only has one client
          contextRole.settings.clientId = this.selectedClientAdvertiser[0]?.id;
        } else if (this.selectedClientAdvertiser) {
          contextRole.settings.clientId = this.selectedClientAdvertiser.id;
        } else {
          contextRole.settings.clientId = null;
        }
      } else {
        delete contextRole.settings.clientId;
      }

      contextRole.settings.ids = this.idSettings ? this.idSettings : delete contextRole.settings.ids;

      return contextRole;
    },
    idSettings() {
      switch (this.mainRole?.id) {
        case ROLES.AD_OPS.id:
        case ROLES.CLIENT.id:
          return this.selectedClientsAdOps.map(client => client.id);
        case ROLES.PUBLISHER.id:
          return this.selectedPublishers.map(publisher => publisher.id);
        case ROLES.SUPER_ADVERTISER.id:
        case ROLES.ADVERTISER.id:
          return this.selectedAdvertisers.map(advertiser => advertiser.id);
        default:
          return [];
      }
    },
    contextClient() {
      return this.user.clients.filter(client => client.type === CONTEXTS.ECOMMERCE.id);
    },
    showAdvertiserSelector() {
      return this.isAdvertiser && !!this.selectedClientAdvertiser;
    },
    advertiserSelectKey() {
      const selectedId = this.selectedClientAdvertiser?.id;
      return selectedId || 0;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.initClients();
        this.initPublishers();
        this.initAdvertisers();
        this.setMainRole();
      },
    },
    mainRole() {
      if (this.simple) {
        this.initClients();
      }
    },
  },
  created() {
    this.initClients();
    this.initPublishers();
    this.initAdvertisers();
    this.setMainRole();
  },
  methods: {
    getClients,
    getPublishers(params = new QueryParamsBuilder()) {
      return getPublishers(params, this.selectedClientPublishers.map(client => client.id).toString());
    },
    getAdvertisers(params) {
      params.addFilter('client.id', this.selectedClientAdvertiser.id);
      return getAdvertisers(params);
    },
    initClients() {
      this.selectedClientsAdOps = this.isAdOpsOrClient ? this.contextClient : [];
      this.selectedClientPublishers = this.contextClient.length ? this.contextClient : null;
      this.selectedClientAdvertiser = this.contextClient.length ? this.contextClient : null;
    },
    initPublishers() {
      this.selectedPublishers = this.user?.publishers || [];
    },
    initAdvertisers() {
      this.selectedAdvertisers = this.user?.advertisers || [];
    },
    onClientChangeAdOps({ items }) {
      this.selectedClientsAdOps = items;
      this.selectedPublishers = [];
      this.selectedAdvertisers = [];

      this.emitValue();
    },
    onClientChangePublisher({ items }) {
      this.selectedClientPublishers = items;
      this.selectedPublishers = [];

      this.emitValue();
    },
    onPublishersChange({ items }) {
      this.selectedPublishers = items;
      this.emitValue();
    },
    onClientChangeAdvertiser({ items }) {
      this.selectedClientAdvertiser = items;
      this.selectedAdvertisers = [];

      this.emitValue();
    },
    onAdvertisersChange({ items }) {
      this.selectedAdvertisers = items;
      this.emitValue();
    },
  },
};
</script>

<style scoped>
::v-deep .custom-form-row > div:nth-child(1) {
  @apply hidden;
}
::v-deep .custom-form-row > div:nth-child(2) {
  @apply ml-0;
}
</style>
