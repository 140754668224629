<template>
  <div class="flex flex-col items-center w-full context-selector">
    <context-role-selector-base
      v-model="mainRole"
      :simple="simple"
      class="w-full"
      :class="{ 'custom-form-row': simple }"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="roles"
      @change="onMainRoleChange"
    />

    <form-row v-if="mainRole" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Salesforce User">
          <asterix-async-select
            id="seller-select-filter"
            close-on-select
            disable-selected-options
            label="name"
            name="seller-select-filter"
            :service="getSalesforceUsers"
            track-by="id"
            :value="selectedSalesforceUser"
            @change="onSalesforceUserChange"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isClientOrSeller" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Office">
          <asterix-async-select
            :value="selectedClients"
            :service="getClients"
            is-array-object
            :disabled="simple"
            multiple
            add-hex-color="orange"
            required="required"
            @change="onClientsChange"
          />
        </sun-label-group>
      </template>
    </form-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect.vue';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ROLES } from '@/model/shared/roles';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { getSalesforceUsers, getSalesforceUserById } from '@/services/modules/Core/salesforce';
import { getClients } from '@/services/modules/dashboardSSP/client/getClients';
import { CONTEXTS } from '@/model/shared/contexts';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';
import ApiRequest from '@/utils/apiRequest';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'DashboardSSPContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    AsterixAsyncSelect,
    FormRow,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    simple: {
      type: Boolean,
      default: () => false,
    },
    roles: {
      type: Array,
      default: () => CONTEXTS.DASHBOARD_SSP.roles,
    },
  },
  data: () => ({
    selectedClients: [],
    selectedSalesforceUser: null,
  }),
  computed: {
    contextName() {
      return CONTEXTS.DASHBOARD_SSP.name;
    },
    isClientOrSeller() {
      return [ROLES.CLIENT.id, ROLES.SELLER.id].includes(this.mainRole?.id);
    },
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.DASHBOARD_SSP.id, settings: {} };
      contextRole.settings = contextRole.settings || {};

      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }

      if (this.isClientOrSeller) {
        contextRole.settings.ids = this.selectedClients.map(client => client.id);
      } else {
        delete contextRole.settings.ids;
      }

      contextRole.settings.salesforceId = this.selectedSalesforceUser?.id;

      return contextRole;
    },
    contextClient() {
      return this.user.clients.filter(client => client.type === CONTEXTS.DASHBOARD_SSP.id);
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.initClients();
        this.setMainRole();
      },
    },
    mainRole() {
      if (this.simple) {
        this.initClients();
      }
    },
  },
  created() {
    this.initClients();
    this.setMainRole();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    getClients,
    getSalesforceUsers,
    initClients() {
      this.loadSalesforceUser();
      this.selectedClients = this.isClientOrSeller ? this.contextClient : [];
    },
    async loadSalesforceUser() {
      const salesforceId = this.value?.settings?.salesforceId;
      if (!salesforceId) return;

      await ApiRequest(async () => {
        const { data } = await getSalesforceUserById(salesforceId);
        this.selectedSalesforceUser = data;
      }).catch(() => {
        this.createToast(Toast.error('Error', 'Salesforce user not found'));
      });
    },
    onSalesforceUserChange({ items }) {
      this.selectedSalesforceUser = items;
      if (!this.simple) {
        this.selectedClients = [];
      }

      this.emitValue();
    },
    onClientsChange({ items }) {
      this.selectedClients = items;
      this.emitValue();
    },
  },
};
</script>

<style scoped>
::v-deep .custom-form-row > div:nth-child(1) {
  @apply hidden;
}
::v-deep .custom-form-row > div:nth-child(2) {
  @apply ml-0;
}
</style>
