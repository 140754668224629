<template>
  <div class="flex flex-col items-center w-full context-selector">
    <context-role-selector-base
      v-model="mainRole"
      :simple="simple"
      class="w-full"
      :class="{ 'custom-form-row': simple }"
      :toggle="{ text: contextName, checked: isToggleChecked }"
      :options="roles"
      @change="onMainRoleChange"
    />

    <form-row v-if="isClientOrSeller" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelectorThirdParty"
            name="clientSelectorThirdParty"
            :value="selectedClients"
            :service="getClients"
            :disabled="simple"
            multiple
            required="required"
            @change="onClientChange"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isAdvertiser" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Client">
          <asterix-async-select
            id="clientSelectorThirdParty"
            key="client-selector"
            :disabled="simple"
            name="clientSelectorThirdParty"
            required="required"
            :service="getClients"
            :value="selectedClientAdvertiser"
            @change="onClientChangeAdvertiser"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row v-if="isAdvertiser && showAdvertiserSelector" :class="{ 'custom-form-row': simple }">
      <template #right>
        <sun-label-group text="Advertisers">
          <asterix-async-select
            id="advertiserSelectorThirdParty"
            key="advertiser-selector"
            multiple
            name="advertiserSelectorThirdParty"
            required="required"
            search-key="advertiser.name"
            :service="getAdvertisers"
            :value="selectedAdvertisers"
            @change="onAdvertisersChange"
          />
        </sun-label-group>
      </template>
    </form-row>
  </div>
</template>

<script>
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import ContextRoleSelectorBase from '@/components/atoms/ContextRoleSelectorBase';
import { ROLES } from '@/model/shared/roles';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { getClients } from '@/services/modules/ThirdParty/client';
import { getAdvertisers } from '@/services/modules/ThirdParty/advertiser';
import { CONTEXTS } from '@/model/shared/contexts';
import { ContextRoleSelectorMixin } from '@/mixins/modules/core/ContextRoleSelectorMixin';

export default {
  name: 'ThirdPartyContextRolesSelector',
  components: {
    ContextRoleSelectorBase,
    AsterixAsyncSelect,
    FormRow,
  },
  mixins: [ContextRoleSelectorMixin],
  props: {
    simple: {
      type: Boolean,
      default: () => false,
    },
    roles: {
      type: Array,
      default: () => CONTEXTS.THIRD_PARTY.roles,
    },
  },
  data: () => ({
    selectedAdvertisers: [],
    selectedClients: [],
    selectedClientAdvertiser: null,
  }),
  computed: {
    isAdvertiser() {
      return this.mainRole?.id === ROLES.ADVERTISER.id;
    },
    isClientOrSeller() {
      return [ROLES.CLIENT.id, ROLES.SELLER.id].includes(this.mainRole?.id);
    },
    showAdvertiserSelector() {
      return this.isAdvertiser && !!this.selectedClientAdvertiser;
    },
    contextName() {
      return CONTEXTS.THIRD_PARTY.name;
    },
    // mixin method re-write
    currentContextRole() {
      const contextRole = this.value || { context: CONTEXTS.THIRD_PARTY.id, settings: {} };
      contextRole.settings = contextRole.settings || {};

      if (this.mainRole?.id) {
        contextRole.role = this.mainRole.id;
      }

      if (this.isAdvertiser) {
        contextRole.settings.clientId = this.selectedClientAdvertiser?.id;
      } else {
        delete contextRole.settings.clientId;
      }

      if (this.isClientOrSeller || this.isAdvertiser) {
        contextRole.settings.ids = this.isClientOrSeller
          ? this.selectedClients.map(client => client.id)
          : this.selectedAdvertisers.map(advertiser => advertiser.id);
      } else {
        delete contextRole.settings.ids;
      }
      return contextRole;
    },
    contextClient() {
      return this.user.clients.filter(client => client.type === CONTEXTS.THIRD_PARTY.id);
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.initClients();
        this.initAdvertisers();
        this.setMainRole();
      },
    },
    mainRole() {
      if (this.simple) {
        this.initClients();
      }
    },
  },
  created() {
    this.initClients();
    this.initAdvertisers();
    this.setMainRole();
  },
  methods: {
    getClients,
    getAdvertisers(params) {
      params.addFilter('client.id', this.selectedClientAdvertiser.id);
      return getAdvertisers(params);
    },
    initClients() {
      this.selectedClients = this.isClientOrSeller ? this.contextClient : [];
      this.selectedClientAdvertiser = this.contextClient.length ? this.contextClient[0] : null;
    },
    initAdvertisers() {
      this.selectedAdvertisers = this.user?.advertisers || [];
    },
    onClientChange({ items }) {
      this.selectedClients = items;
      this.selectedAdvertisers = [];

      this.emitValue();
    },
    onClientChangeAdvertiser({ items }) {
      this.selectedClientAdvertiser = items;
      this.selectedAdvertisers = [];

      this.emitValue();
    },
    onAdvertisersChange({ items }) {
      this.selectedAdvertisers = items;
      this.emitValue();
    },
  },
};
</script>

<style scoped>
::v-deep .custom-form-row > div:nth-child(1) {
  @apply hidden;
}
::v-deep .custom-form-row > div:nth-child(2) {
  @apply ml-0;
}
</style>
