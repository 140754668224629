<template>
  <form-row class="context-selector-base items-center">
    <template v-if="!simple" #left>
      <div class="flex flex-1 mb-2 sm:mb-0">
        <sun-toggle
          :id="`toggle-${toggleText}`"
          v-model="toggleValue"
          :name="`toggle-${toggleText}`"
          :class="{ 'opacity-50': toggleDisabled }"
          :disabled="toggleDisabled"
          @change="emitEvents()"
        />
        <span class="ml-4 text-left" :class="{ 'font-bold': toggleValue }">{{ toggleText }}</span>
      </div>
    </template>
    <template #right>
      <sun-select
        :id="`select-${toggle.value}`"
        v-model="selectedRole"
        :name="selectName"
        :options="options"
        track-by="id"
        label="name"
        color-tag="gray"
        :disabled="disabledRoleSelect"
        :required="isSelectRequired"
        text-error="Select option"
      />
    </template>
  </form-row>
</template>

<script>
import { ERRORS } from '@/i18n/forms/errors';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'ContextRoleSelectorBase',
  components: {
    FormRow,
  },
  props: {
    toggle: {
      type: Object,
      required: true,
      validator: toggle => !!toggle.text,
    },
    options: {
      type: Array,
      default: () => null,
    },
    value: {
      type: [String, Object],
      default: () => null,
    },
    required: {
      type: String,
      default: () => null,
    },
    simple: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    toggleValue: null,
    selectedRole: null,
    i18nErrors: ERRORS.en,
    isValid: true,
  }),
  computed: {
    toggleDisabled() {
      return this.toggle?.disabled || false;
    },
    toggleText() {
      return this.toggle.text;
    },
    selectName() {
      return `select-${this.toggleText.replace(' ', '')}`;
    },
    isSelectRequired() {
      return this.toggleValue || !!this.required ? 'required' : null;
    },
    disabledRoleSelect() {
      return !this.toggleValue || this.options.length === 0;
    },
  },
  watch: {
    selectedRole(newRole) {
      this.emitEvents(newRole);
    },
    value(newValue) {
      this.selectedRole = newValue;
    },
    toggle(value) {
      this.toggleValue = value?.checked;
    },
  },
  created() {
    this.toggleValue = this.toggle?.checked;
    this.selectedRole = this.value;
    if (this.simple) {
      this.toggleValue = true;
      this.emitEvents();
    }
  },
  methods: {
    emitEvents() {
      if (!this.toggleValue) {
        this.selectedRole = null;
      }
      this.$emit('input', this.selectedRole);
      this.$emit('change', this.selectedRole);
    },
  },
};
</script>
